import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import '../../../assets/css/container/gallery.css';
import Modal from '../modal';

// gallery images
import G1 from '../../../assets/img/gallery/G1.JPG';
import G2 from '../../../assets/img/gallery/G2.JPG';
import G3 from '../../../assets/img/gallery/G3.JPG';
import G4 from '../../../assets/img/gallery/G4.JPG';
import G5 from '../../../assets/img/gallery/G5.JPG';
import G6 from '../../../assets/img/gallery/G6.JPG';
import G7 from '../../../assets/img/gallery/G7.JPG';
import G8 from '../../../assets/img/gallery/G8.jpg';
import G9 from '../../../assets/img/gallery/G9.jpg'; 
import G10 from '../../../assets/img/gallery/G10.jpg';
import G11 from '../../../assets/img/gallery/G11.jpg';
import G12 from '../../../assets/img/gallery/G12.jpg';
import G13 from '../../../assets/img/gallery/G13.jpg';
import G14 from '../../../assets/img/gallery/G14.jpg';
import G15 from '../../../assets/img/gallery/G15.jpg';
import G16 from '../../../assets/img/gallery/G16.jpg';
import G17 from '../../../assets/img/gallery/G17.jpg';
import G18 from '../../../assets/img/gallery/G18.jpg';
import G19 from '../../../assets/img/gallery/G19.jpg';
import G20 from '../../../assets/img/gallery/G20.jpg';
import G21 from '../../../assets/img/gallery/G21.jpg';
import G22 from '../../../assets/img/gallery/G22.jpg';
import G23 from '../../../assets/img/gallery/G23.jpg';
import G24 from '../../../assets/img/gallery/G24.jpg';
import G25 from '../../../assets/img/gallery/G25.jpg';
import G26 from '../../../assets/img/gallery/G26.jpg';
import G27 from '../../../assets/img/gallery/G27.jpg';
import G28 from '../../../assets/img/gallery/G28.jpg';
import G29 from '../../../assets/img/gallery/G29.jpg';
import G30 from '../../../assets/img/gallery/G30.jpg';
import G31 from '../../../assets/img/gallery/G31.jpg';
import G32 from '../../../assets/img/gallery/G32.jpg';




const Gallery = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState({ src: '', alt: '' });

  const handleImageClick = (src, alt) => {
    setCurrentImage({ src, alt });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="gallery-container">
      <section className="intro-section">
        <h1>Welcome to Kadi's Hair Gallery</h1>
        <p>Your style, our passion.</p>
      </section>

      <section className="gallery-preview">
        <h2>Our Work</h2>
        <div className="gallery-grid">
          <img src={G1} alt="Gallery 1" onClick={() => handleImageClick(G1, 'Gallery 1')} />
          <img src={G2} alt="Gallery 2" onClick={() => handleImageClick(G2, 'Gallery 2')} />
          <img src={G3} alt="Gallery 3" onClick={() => handleImageClick(G3, 'Gallery 3')} />
          <img src={G4} alt="Gallery 4" onClick={() => handleImageClick(G4, 'Gallery 4')} />
          <img src={G5} alt="Gallery 5" onClick={() => handleImageClick(G5, 'Gallery 5')} />
          <img src={G6} alt="Gallery 6" onClick={() => handleImageClick(G6, 'Gallery 6')} />
          <img src={G7} alt="Gallery 7" onClick={() => handleImageClick(G7, 'Gallery 7')} />
          <img src={G8} alt="Gallery 8" onClick={() => handleImageClick(G8, 'Gallery 8')} />
          <img src={G9} alt="Gallery 9" onClick={() => handleImageClick(G9, 'Gallery 9')} />
          <img src={G10} alt="Gallery 10" onClick={() => handleImageClick(G10, 'Gallery 10')} />
          <img src={G11} alt="Gallery 11" onClick={() => handleImageClick(G11, 'Gallery 11')} />
          <img src={G12} alt="Gallery 12" onClick={() => handleImageClick(G12, 'Gallery 12')} />
          <img src={G13} alt="Gallery 13" onClick={() => handleImageClick(G13, 'Gallery 13')} />
          <img src={G14} alt="Gallery 14" onClick={() => handleImageClick(G14, 'Gallery 14')} />
          <img src={G15} alt="Gallery 15" onClick={() => handleImageClick(G15, 'Gallery 15')} />
          <img src={G16} alt="Gallery 16" onClick={() => handleImageClick(G16, 'Gallery 16')} />
          <img src={G17} alt="Gallery 17" onClick={() => handleImageClick(G17, 'Gallery 17')} />
          <img src={G18} alt="Gallery 18" onClick={() => handleImageClick(G18, 'Gallery 18')} />
          <img src={G19} alt="Gallery 19" onClick={() => handleImageClick(G19, 'Gallery 19')} />
          <img src={G20} alt="Gallery 20" onClick={() => handleImageClick(G20, 'Gallery 20')} />
          <img src={G21} alt="Gallery 21" onClick={() => handleImageClick(G21, 'Gallery 21')} />
          <img src={G22} alt="Gallery 22" onClick={() => handleImageClick(G22, 'Gallery 22')} />
          <img src={G23} alt="Gallery 23" onClick={() => handleImageClick(G23, 'Gallery 23')} />
          <img src={G24} alt="Gallery 24" onClick={() => handleImageClick(G24, 'Gallery 24')} />
          <img src={G25} alt="Gallery 25" onClick={() => handleImageClick(G25, 'Gallery 25')} />
          <img src={G26} alt="Gallery 26" onClick={() => handleImageClick(G26, 'Gallery 26')} />
          <img src={G27} alt="Gallery 27" onClick={() => handleImageClick(G27, 'Gallery 27')} />
          <img src={G28} alt="Gallery 28" onClick={() => handleImageClick(G28, 'Gallery 28')} />
          <img src={G29} alt="Gallery 29" onClick={() => handleImageClick(G29, 'Gallery 29')} />
          <img src={G30} alt="Gallery 30" onClick={() => handleImageClick(G30, 'Gallery 30')} />
          <img src={G31} alt="Gallery 31" onClick={() => handleImageClick(G31, 'Gallery 31')} />
          <img src={G32} alt="Gallery 32" onClick={() => handleImageClick(G32, 'Gallery 32')} />
        </div>
      </section>

      {/* Modal Component */}
      <Modal isOpen={isModalOpen} onClose={closeModal} imageSrc={currentImage.src} imageAlt={currentImage.alt} />
    </div>
  );
};

export default Gallery;
