import React from "react";
import '../../../assets/css/container/policies.css';

import { FaDoorOpen, FaClock, FaShower, FaCalendarDay } from 'react-icons/fa';


const Policies = () => {
    return (
      <div className="policies-container">
        <h1 className="policies-header">PLEASE REVIEW POLICIES BEFORE COMING IN TO BE SERVICED</h1>
        <div className="policies-grid">
          <div className="policy">
            <FaDoorOpen className="policy-icon" />
            <p>EARLY APPOINTMENTS ARE <span className="highlight">WALK-INS ONLY</span><br />
                LATE APPOINTMENTS ARE BY <span className="highlight">BOOKING</span> WITH EXTRA CHARGE</p>
          </div>
          <div className="policy">
            <FaClock className="policy-icon" />
            <p>OUR LATE NIGHT HOURS (6PM-12AM) HAS A $40 FEE APPLIED TO EVERY SERVICE</p>
          </div>
          <div className="policy">
            <FaShower className="policy-icon" />
            <p>PLEASE ARRIVE WASHED/BLOW DRIED STRAIGHT OR BE PREPARED TO PAY THE $35 WASH FEE FOR US TO DO IT</p>
          </div>
          <div className="policy">
            <FaCalendarDay className="policy-icon" />
            <p>SUNDAYS BY <span className="highlight">APPOINTMENTS ONLY</span> FROM 10AM - 6PM</p>
          </div>
        </div>
        <h2 className="policies-footer">PLEASE REVIEW POLICIES BEFORE COMING IN TO BE BRAIDED</h2>
      </div>
    );
  };

export default Policies;