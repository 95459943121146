import React from "react";
import '../../assets/css/container/services/ladies.css';
import kidStyle from '../../assets/img/kids.png';
import { HashLink } from "react-router-hash-link";


const Ladies = () => {
    const services = [
      { name: "Box Braids", price: "$180 & UP" },
      { name: "Soft Locs Up to Waist", price: "$200" },
      { name: "Soft Locs / Butt Length", price: "$250 & UP" },
      { name: "Butterfly Locs / Waist", price: "$180 AND UP" },
      { name: "Butterfly Locs / Butt Length", price: "$200 & UP" },
      { name: "Goddess Locs", price: "$200 AND UP" },
      { name: "Faux Locs / Waist", price: "$180 AND UP" },
      { name: "Faux Locs / Butt Length", price: "$200 AND UP" },
      { name: "Touch Ups", price: "$75 AND UP" },
      { name: "Take Out Weave + Wash & Dry", price: "$65 AND UP" },
      { name: "Take Out Braid Style + Wash & Dry", price: "$70 AND UP" },
      { name: "Single Braids", price: "$180 & UP" },
      { name: "Micro Locs", price: "$400 & UP" },
      { name: "Re-Twist", price: "$85 & UP" },
      { name: "2 Strand Twist", price: "$85 & UP" },
      { name: "3 Layer Cornrows", price: "$200 & UP" },
      { name: "2 Layer Cornrows", price: "$150 & UP" },
      { name: "Marley Twist (Special)", price: "$200 & UP" },
      { name: "2 Cornrows Up to Waist", price: "$50" },
      { name: "Boho Braids", price: "$200 & UP **Curly hair Not Provided**" },
      { name: "Tribal Weave", price: "$200 & UP" },
      { name: "Interlock Twist", price: "$120 & UP"},
      { name: "Senegalese Twist", price: "$180 & UP" },
    ];
  
    return (
      <section className="price-list">

        <p className="heading">
          <HashLink to='/gentlemen#' className="ladies-gent-heading">
              {" "}
              Go to the Gentlemen services{" "}
          </HashLink>
        </p>

        <div className="hero-banner">
          <h1>Kadi's Hair Gallery</h1>
          <h2>Price List</h2>
          <p className="disclaimer">All prices are subject to change.</p>
        </div>
        <div className="ladies-category">
          {services.map((item, index) => (
            <p key={index}>
              {item.name} <span className="price">{item.price}</span>
            </p>
          ))}
        </div>
        <div className="kid-styles">
          <h3>Kid Prices</h3>
          <p>Box Braids $130 & UP</p>
          <p>Feed-In's Cornrows $75 & UP</p>
          <p>2 Layer Cornrows $100 & UP</p>
          <p>Boho $150 & UP</p>
          <p>Senegalese Braids $150 & UP</p>
          <img src={kidStyle} alt="Kid Styles" />
        </div>
      </section>
    );
};

export default Ladies;
