import React, { useState } from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

import '../../assets/css/container/makeappointment.css';

const MakeAppointment = () => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [date, setDate] = useState(null);
    const [time, setTime] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Appointment details:', { name, number, date, time });
        setName('');
        setNumber('');
        setDate(null);
        setTime('');
    };

    const timeOptions = [];
    for (let i = 0; i < 24; i++) {
        const hour = i % 12 || 12; 
        const ampm = i < 12 ? 'AM' : 'PM';
        const time12hr = `${hour}:00 ${ampm}`;
        const time24hr = `${String(i).padStart(2, '0')}:00`;
        timeOptions.push({ value: time24hr, label: time12hr });
    }

    return (
        <section className="section-sm context-dark bg-secondary">
            <div className="make-appointment-container">
                <div className="make-appointment">
                    <form onSubmit={handleSubmit} className="form-container">
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input
                                type="text"
                                id="name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="number">Phone Number:</label>
                            <input
                                type="tel"
                                id="number"
                                value={number}
                                onChange={(e) => setNumber(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="date">Date:</label>
                            <DatePicker
                                id="date"
                                selected={date}
                                onChange={(date) => setDate(date)}
                                dateFormat="yyyy-MM-dd"
                                required
                                autoComplete='off'
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="time">Time:</label>
                            <select
                                id="time"
                                value={time}
                                onChange={(e) => setTime(e.target.value)}
                                required
                                autoComplete='off'
                            >
                                <option value="" disabled>Select a time</option> {/* Placeholder for time */}
                                {timeOptions.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </div>

                        <p className="button-container">
                            <button type="submit" className="make-appointment-button">Make Appointment</button>
                        </p>
                    </form>
                </div>
            </div>
        </section>
    );
};


export default MakeAppointment;
